<template>
	<ResearchPaper v-if="template && template.length"></ResearchPaper>
</template>

<script>
import ToolsResearchPaper from "@/components/shared/tools/researchPaper/Index.vue";
import ResearchPaper from "@/components/research/modules/write/stages/researchPaper/Index.vue";
import { mapState } from "vuex";

export default {
	name: "ResearchPaperWork",

	components: {
		ResearchPaper,
	},

	computed: {
		...mapState({
			template: (state) => state.researchPaper.template,
		}),
	},

	mounted() {
		this.getResearchPaper();
	},

	methods: {
		async getResearchPaper() {
			await this.$store.dispatch("researchPaper/template", {
				clear: false,
			});
		},
	},
};
</script>

<style></style>
